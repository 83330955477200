var app = {};

app.init = function () {
  app.tour();
  app.video();
  app.videopreview();
  app.hamburger();
  app.slider();
  app.galleryModal();
  app.dropdown();
  app.lyrics();
  app.accessibleNavDropdown();
};

app.tour = function () {
  const tourContainer = $("#tourDates");

  // ATTN: PATH TO PRODUCTION API NEEDS TO BE EDITED HERE
  $.ajax({
    type: "GET",
    url: "https://tour.dianakrall.com/api/liveevents/dianakrall",
    success: function (result) {
      parseData(result);
    },
  });

  function parseData(responseData) {
    const events = responseData;
    let items = "";

    if (events.length === 0) $("#noDates").css("display", "block");

    for (let i = 0; i < 5; i++) {
      let event = events[i].LiveEvent;

      // Get the Date and put it in a <div>
      let date = formatDate(
        new Date(event.EventDate.replace(/-/g, "/").replace(/T.+/, ""))
      );
      let dateWrap = $("<div>");
      dateWrap.addClass("date-wrapper");
      dateWrap.append(date);

      // Get the Venue and Location Details and put it in a <div>
      let venue = event.Venue;
      let venueLink = $("<a>");
      venueLink.attr("href", event.VenueUrl);
      venueLink.attr("target", "_blank");
      venueLink.addClass("venue-link");
      venueLink.append(venue);
      //city
      let city = event.City;
      let region = event.Region;
      let country = event.Country;
      let note = event.EventListNote;
      let cityParagraph = $('<p class="city">');
      cityParagraph.append(city, `, `);
      if (region) cityParagraph.append(region, `, `);
      cityParagraph.append(country);
      cityParagraph.append("<br>", note);
      //wrap
      let locationWrap = $("<div>");
      locationWrap.addClass("venue-city-wrapper");
      locationWrap.append(venueLink, cityParagraph);

      // Get the Ticket Details and put it in a link
      let ticketText;
      let ticketEvent;

      if (event.ExternalTicketUrl)
        (ticketText = "Buy Tickets"), (ticketEvent = event.ExternalTicketUrl);

      let ticketLink = $("<a>");
      ticketLink.addClass("button-style-1");
      ticketLink.attr("href", ticketEvent);
      ticketLink.attr("target", "_blank");
      ticketLink.append(ticketText);

      // if ticket purchase button is included in this tour widget, include ticketWrap in listItem.append below
      let ticketWrap = $("<div>");
      ticketWrap.addClass("ticket-wrapper");
      if (event.ExternalTicketUrl) ticketWrap.append(ticketLink);

      
      let listItem = $("<li>");
      listItem.addClass("tour-date");
      //HERE is where all the possible columns get added to the li
      listItem.append(dateWrap, locationWrap, ticketWrap);

      let listItemHtml = listItem[0].outerHTML;
      items += listItemHtml;
    }
    addToPage(items);
  }

  function addToPage(items) {
    tourContainer.append(items);
  }

  function formatDate(date) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    let day = date.getDate();
    let dayString = ("0" + day).slice(-2);
    let monthIndex = date.getMonth();
    let year = date.getFullYear();

    return (
      '<span class="month">' +
      monthNames[monthIndex] +
      " </span>" +
      '<span class="day">' +
      dayString +
      ", </span>" +
      '<span class="year">' +
      year +
      "</span>"
    );
  }
};

//Youtube Video Embed
app.video = function () {
  var div,
    n,
    v = document.getElementsByClassName("youtube-player-single");
  for (n = 0; n < v.length; n++) {
    div = document.createElement("button");
    div.setAttribute("data-id", v[n].dataset.id);
    div.setAttribute("data-title", v[n].dataset.title);
    div.setAttribute("title", v[n].dataset.title);
    div.setAttribute("class", "video-player-button");
    div.innerHTML = videoThumb(v[n].dataset.id);
    div.onclick = videoIframe;
    v[n].appendChild(div);
  }

  function videoThumb(id) {
    let imgAlt = v[n].dataset.title;
    var thumb = `<img src="https://i.ytimg.com/vi/ID/hqdefault.jpg" alt="${imgAlt}">`,
      play = '<div class="play"></div>';
    return thumb.replace("ID", id) + play;
  }

  function videoIframe() {
    var iframe = document.createElement("iframe");
    var embed = "https://www.youtube.com/embed/ID?autoplay=1";
    iframe.setAttribute("src", embed.replace("ID", this.dataset.id));
    iframe.setAttribute("title", this.dataset.title);
    iframe.setAttribute("frameborder", "0");
    iframe.setAttribute("allowfullscreen", "1");
    this.parentNode.replaceChild(iframe, this);
  }
};

// variation of video function for landing pages, does not play video inline
app.videopreview = function () {
  var div,
    n,
    v = document.getElementsByClassName("youtube-player-preview");
  for (n = 0; n < v.length; n++) {
    div = document.createElement("div");
    div.setAttribute("data-id", v[n].dataset.id);
    div.setAttribute("data-title", v[n].dataset.title);
    div.innerHTML = videoThumb(v[n].dataset.id);
    v[n].appendChild(div);
  }

  function videoThumb(id) {
    let imgAlt = v[n].dataset.title;
    var thumb = `<img src="https://i.ytimg.com/vi/ID/hqdefault.jpg" alt="${imgAlt}">`;
    return thumb.replace("ID", id);
  }
};

//Mobile Hamburger Menu
app.hamburger = function () {
  $(".hamburger").on("click", function () {
    let isNavOpen = $(".body-with-open-nav");

    if (isNavOpen.length > 0) {
      $(".header-mobile-wrapper").attr("aria-hidden", "true");
    } else {
      $(".header-mobile-wrapper").attr("aria-hidden", "false");
    }
    $(".hamburger").toggleClass("is-active");
    $("body").toggleClass("body-with-open-nav");
  });

  // Tabbing through last menu item closes the modal
  //
  //ATTN: IF THE HEADER CONTENT HAS BEEN CHANGED FROM STARTER THEME DEFAULT, CORRECT HTML SELECTOR AND BROWSER WIDTH AT WHICH HEADER SWITCHES TO MOBILE NEEDS TO BE EDITED HERE
  //
  // direct child selector is included to account for sub menus
  $(".header-primary-nav > li:last-child a").focusout(function () {
    let width = $(window).width();
    if (width < 993) {
      $(".header-mobile-wrapper").attr("aria-expanded", "false");
      $("body").removeClass("body-with-open-nav");
      $(".hamburger").toggleClass("is-active");
      $(".hamburger").focus();
    }
  });

  // Pressing on the escape key closes the mobile menu
  $(document).keyup(function (e) {
    if (e.key === "Escape") {
      if ($("body").hasClass("body-with-open-nav")) {
        $(".header-mobile-wrapper").attr("aria-expanded", "false");
        $("body").removeClass("body-with-open-nav");
        $(".hamburger").toggleClass("is-active");
        $(".hamburger").focus();
      }
    }
  });
};

// default slider with auto advance
app.slider = function () {
  if ($(".slider").length) {
    var slideIndex = 0;
    showSlides(slideIndex);

    function plusSlides(n) {
      showSlides((slideIndex += n));
    }

    function autoAdvance() {
      plusSlides(1);
    }

    autoAdvance();

    var timerId = setInterval(autoAdvance, 9000);

    $(".slider-prev").click(function () {
      plusSlides(-1);
      clearInterval(timerId);
    });
    $(".slider-next").click(function () {
      plusSlides(1);
      clearInterval(timerId);
    });
    $(".pause-slideshow").on("click", function () {
      clearInterval(timerId);
      $(this).css("display", "none");
      $(".play-slideshow").css("display", "flex");
    });
    $(".play-slideshow").on("click", function () {
      plusSlides(1);
      timerId = setInterval(autoAdvance, 9000);
      $(this).css("display", "none");
      $(".pause-slideshow").css("display", "flex");
    });

    function showSlides(n) {
      var i;
      var x = document.getElementsByClassName("hero-slides");
      if (n > x.length) {
        slideIndex = 1;
      }
      if (n < 1) {
        slideIndex = x.length;
      }
      for (i = 0; i < x.length; i++) {
        x[i].style.display = "none";
      }
      x[slideIndex - 1].style.display = "block";
    }

    //If only one slide exists, don't show the prev + next + pause buttons
    const slides = document.querySelectorAll(".hero-slides");
    if (slides.length < 2) {
      $(".slider-button").css("display", "none");
    }
  }
};

//slider and modal as used on a single gallery page. Does not autoadvance.
app.galleryModal = function () {
  const imageModal = document.querySelector(".gallery-modal");
  const closeButton = document.querySelector(".gallery-modal-close");

  let previousActiveElement;

  if (imageModal) {
    $(".preview-link").focus(function () {
      if ($(".gallery-modal").hasClass("modal-showing")) {
        closeImageModal();
      }
    });

    let slideIndex = null;

    $(".preview-link").on("click", function () {
      slideIndex = $(this).data("index");
      showSlides((slideIndex += 1));
      previousActiveElement = document.activeElement;
      imageModal.classList.add("modal-showing");
      $("body").addClass("body-with-open-modal");
      $(imageModal).attr("aria-hidden", "false");
      closeButton.focus();
      return previousActiveElement;
    });

    function plusSlides(n) {
      showSlides((slideIndex += n));
    }

    $(".slider-prev").click(function () {
      plusSlides(-1);
    });
    $(".slider-next").click(function () {
      plusSlides(1);
    });

    function showSlides(n) {
      let i;
      let x = document.getElementsByClassName("one-modal-img");
      if (n > x.length) {
        slideIndex = 1;
      }
      if (n < 1) {
        slideIndex = x.length;
      }
      for (i = 0; i < x.length; i++) {
        x[i].style.display = "none";
      }
      x[slideIndex - 1].style.display = "block";
    }

    closeButton.addEventListener("click", closeImageModal);

    //ESCAPE BUTTON CLOSES MODAL
    $(document).keydown(function (event) {
      if (event.keyCode == 27) {
        closeImageModal();
      }
    });

    function closeImageModal() {
      imageModal.classList.remove("modal-showing");
      $("body").removeClass("body-with-open-modal");
      $(imageModal).attr("aria-hidden", "true");
      previousActiveElement.focus();
    }
  }
};

app.dropdown = function () {
  //dropdown general functionality
  $(".dropdown").click(function () {
    if ($(this).hasClass("button-with-open-content")) {
      $(this).next(".dropdown-content").removeClass("dropdown-is-open");
      $(this).attr("aria-expanded", "false");
      $(this).removeClass("button-with-open-content");
    } else {
      $(this).next(".dropdown-content").addClass("dropdown-is-open");
      $(this).attr("aria-expanded", "true");
      $(this).addClass("button-with-open-content");
    }
  });
};

app.lyrics = function () {
  //dropdown with added functionality of closing any open lyrics on click of new one
  $(".lyrics-header").on("click", function () {
    const currentSong = $(this).parent().parent(".each-track");
    $(currentSong).siblings().removeClass("lyrics-showing");

    $(currentSong)
      .siblings()
      .find(".lyrics-header")
      .attr("aria-expanded", "false");

    if (currentSong.hasClass("lyrics-showing")) {
      $(this).attr("aria-expanded", "false");
      currentSong.removeClass("lyrics-showing");
    } else {
      $(this).attr("aria-expanded", "true");
      currentSong.addClass("lyrics-showing");
    }
  });
};

app.accessibleNavDropdown = function () {
  $(".menu-item-has-children>a")
    .attr("aria-haspopup", "true")
    .attr("aria-expanded", "false");

  $(".menu-item-has-children").on("mouseenter focusin", function () {
    $(".menu-item-has-children>a").attr("aria-expanded", "true");
  });

  $(".menu-item-has-children").on("mouseleave focusout", function () {
    $(".menu-item-has-children>a").attr("aria-expanded", "false");
  });
};

var NOP = NOP || {};

// ATTN: MEMBERSHIP URL NEEDS TO BE EDITED HERE
NOP.MembershipUrl = "";
NOP.AuthenticationCookieName = "NOPCOMMERCE.AUTH";

NOP.Membership = function (membershipUrl, authenticationCookie) {
  var root = membershipUrl;
  var authenticationCookieName = authenticationCookie;
  var username = "";

  function generateLoginUrl(element) {
    element.attr(
      "href",
      root + "/login?ReturnUrl=" + encodeURIComponent(location.href)
    );
  }

  function generateLogoutUrl(element) {
    element.attr(
      "href",
      root + "/logout?ReturnUrl=" + encodeURIComponent(location.href)
    );
  }

  function generateMemberUrl(element) {
    element.attr(
      "href",
      root + "/customer/info?ReturnUrl=" + encodeURIComponent(location.href)
    );
  }

  function isLoggedIn() {
    username = "";
    var result = false;
    var token = getCookie(authenticationCookieName);
    if (token) {
      result = true;
    }

    return result;
  }

  function isSubscriber(subscriptions) {
    username = "";
    var result = false;
    for (var i = 0; i < subscriptions.length; i++) {
      subscriptions[i] = subscriptions[i].toLowerCase();
    }
    var token = getCookie(authenticationCookieName);
    if (token) {
      var isSubscriberUrl =
        root + "/webapi/membership/getauthenticatedcustomer";
      $.ajax({
        type: "GET",
        url: isSubscriberUrl,
        async: false,
        headers: {
          "Content-type": "application/json",
          Authorization: "NOP " + token,
        },
        success: function (response) {
          username = "";
          for (var i = 0; i < response.Roles.length; i++) {
            if (
              $.inArray(response.Roles[i].toLowerCase(), subscriptions) != -1
            ) {
              result = true;
              break;
            }
          }
        },
      });
    }

    return result;
  }

  function getUserName() {
    return username;
  }

  function getCookie(cookieName) {
    var re = new RegExp("[; ]" + cookieName + "=([^\\s;]*)");
    var sMatch = (" " + document.cookie).match(re);
    if (cookieName && sMatch) return unescape(sMatch[1]);
    return "";
  }

  return {
    GenerateLoginUrl: generateLoginUrl,
    GenerateLogoutUrl: generateLogoutUrl,
    GenerateMemberUrl: generateMemberUrl,
    IsLoggedIn: isLoggedIn,
    IsSubscriber: isSubscriber,
    UserName: getUserName,
  };
};

NOP.Membership = NOP.Membership(
  NOP.MembershipUrl,
  NOP.AuthenticationCookieName
);

$(document).ready(function () {
  var isloggedin = NOP.Membership.IsLoggedIn();
  if (isloggedin) {
    $("[id^=myLinks]").prepend(
      '<li><a href="#" onclick="NOP.Membership.GenerateLogoutUrl($(this))" class="has-icon"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user-slash" class="svg-icon svg-inline--fa fa-user-slash fa-w-20" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" aria-label="Sign out"><path d="M633.8 458.1L362.3 248.3C412.1 230.7 448 183.8 448 128 448 57.3 390.7 0 320 0c-67.1 0-121.5 51.8-126.9 117.4L45.5 3.4C38.5-2 28.5-.8 23 6.2L3.4 31.4c-5.4 7-4.2 17 2.8 22.4l588.4 454.7c7 5.4 17 4.2 22.5-2.8l19.6-25.3c5.4-6.8 4.1-16.9-2.9-22.3zM96 422.4V464c0 26.5 21.5 48 48 48h350.2L207.4 290.3C144.2 301.3 96 356 96 422.4z"></path></svg>Sign Out</a></li>'
    );
    $("[id^=myLinks]").prepend(
      '<li><a href="#" onclick="NOP.Membership.GenerateMemberUrl($(this))" class="has-icon"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user" class="svg-icon svg-inline--fa fa-user fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" aria-label="My Account"><path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"></path></svg>My Account</a></li>'
    );
  } else {
    $("[id^=myLinks]").prepend(
      '<li><a href="#" onclick="NOP.Membership.GenerateLoginUrl($(this))" class="has-icon"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user" class="svg-icon svg-inline--fa fa-user fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" aria-label="Sign In"><path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"></path></svg>Sign In</a></li>'
    );
    // ATTN: REGISTER LINK NEEDS TO BE EDITED HERE
    $("[id^=myLinks]").prepend(
      '<li><a href="" class="has-icon"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="users" class="svg-icon svg-inline--fa fa-users fa-w-20" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" aria-label="Join"><path d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z"></path></svg>Join</a></li>'
    );
  }

  //call all the app functions
  app.init();
});
